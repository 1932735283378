import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DefaultService } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './request-password-reset.component.html',
  styleUrls: ['./request-password-reset.component.scss'],
})
export class RequestPasswordResetComponent{
  reqSent: boolean = false;
  email: FormControl = new FormControl('', [Validators.required]);

  readonly onDestroy$ = new Subject<void>();

  constructor(
    readonly defaultService: DefaultService,
    readonly router: Router
  ) {}

  sendResetRequest() {
    this.defaultService
      .requestPasswordResetAdmin({
        email: this.email.value,
        resetToken: '',
        newPassword: '',
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.reqSent = true;
      });
  }

  toLogin() {
    this.router.navigate(['../home']);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
