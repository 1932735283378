import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import dayjs from 'dayjs';
import { BookingDTO, DefaultService, SettingDTO } from 'parking-sdk';
import { Subject, forkJoin, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sms-settings',
  templateUrl: './sms-settings.component.html',
  styleUrls: ['./sms-settings.component.scss'],
})
export class SmsSettingsComponent {
  preText = new FormControl<string>('', { nonNullable: true });
  postText = new FormControl<string>('', { nonNullable: true });

  currentConfirmationSMSPreSetting: SettingDTO = {};
  currentConfirmationSMSPostSetting: SettingDTO = {};
  currentArrivalSMSPreSetting: SettingDTO = {};
  currentArrivalSMSPostSetting: SettingDTO = {};

  currentSMSType: 'CONFIRM' | 'ARRIVAL' = 'CONFIRM';
  message: string = '';
  errorPreview = false;

  dummyBooking: BookingDTO = {
    qtyPersons: 2,
    registrationNumber: 'ABC123',
    qr: '123456',
    departureDate: new Date(),
    arrivalDate: dayjs().add(1, 'week').toDate(),
    arrivalFlight: { flightNumber: 'SK123', terminal: 't5' },
    departureFlight: { flightNumber: 'SK456' },
    resource: {
      name: 'PARKING_OUTDOOR',
      label: 'Utomhus',
    },
  };

  postTextChanged: boolean = false;
  preTextChanged: boolean = false;

  readonly onDestroy$ = new Subject<void>();

  constructor(readonly defaultService: DefaultService) {
    this.getPreview();
  }

  ngOnInit() {
    /* Listen to changes */
    this.postText.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.postTextChanged = true;
        this.countSMS();
      });
    this.preText.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.preTextChanged = true;
      this.countSMS();
    });

    /* Get settings and populate form */
    this.getSettings();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  tabChanged(event: MatTabChangeEvent) {
    event.tab.textLabel === 'Bekräftelse'
      ? (this.currentSMSType = 'CONFIRM')
      : (this.currentSMSType = 'ARRIVAL');
    this.getPreview();
    this.getSettings();
  }

  getSettings() {
    if (this.currentSMSType === 'CONFIRM') {
      /* Get confirmation settings */
      forkJoin([
        this.defaultService.getSetting('SMS_PRE_MESSAGE'),
        this.defaultService.getSetting('SMS_POST_MESSAGE'),
      ])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(([pre, post]) => {
          if (pre.value) {
            this.preText.setValue(pre.value, { emitEvent: false });
            this.currentConfirmationSMSPreSetting = pre;
          }
          if (post.value) {
            this.postText.setValue(post.value, { emitEvent: false });
            this.currentConfirmationSMSPostSetting = post;
          }
          this.countSMS();
        });
    } else {
      /* Get arrival settings */
      forkJoin([
        this.defaultService.getSetting('ARRIVAL_SMS_PRE_MESSAGE'),
        this.defaultService.getSetting('ARRIVAL_SMS_POST_MESSAGE'),
      ])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(([pre, post]) => {
          if (pre.value) {
            this.preText.setValue(pre.value, { emitEvent: false });
            this.currentArrivalSMSPreSetting = pre;
          }
          if (post.value) {
            this.postText.setValue(post.value, { emitEvent: false });
            this.currentArrivalSMSPostSetting = post;
          }
          this.countSMS();
        });
    }
  }

  getPreview() {
    this.defaultService
      .getSmsPreviewAdmin(this.dummyBooking, this.currentSMSType)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((text) => {
        if (text.body) {
          this.message = text.body;
          this.errorPreview = false;
        } else {
          console.error('Error fetching preview: ', text);
          this.errorPreview = true;
        }
        this.countSMS();
      });
  }

  save(field: 'PRE' | 'POST', type: 'CONFIRMATION' | 'ARRIVAL') {
    switch (field) {
      /* Update pre setting */
      case 'PRE':
        this.preTextChanged = false;
        if (type === 'CONFIRMATION') {
          this.defaultService
            .updateSetting('SMS_PRE_MESSAGE', {
              ...this.currentConfirmationSMSPreSetting,
              value: this.preText.value,
            })
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((pre) => {
              this.currentConfirmationSMSPreSetting = pre;
              this.getPreview();
            });
        } else if (type === 'ARRIVAL') {
          this.defaultService
            .updateSetting('ARRIVAL_SMS_PRE_MESSAGE', {
              ...this.currentArrivalSMSPreSetting,
              value: this.preText.value,
            })
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((pre) => {
              this.currentArrivalSMSPreSetting = pre;
              this.getPreview();
            });
        }
        break;

      /* Update post setting */
      case 'POST':
        this.postTextChanged = false;
        if (type === 'CONFIRMATION') {
          this.defaultService
            .updateSetting('SMS_POST_MESSAGE', {
              ...this.currentConfirmationSMSPostSetting,
              value: this.postText.value,
            })
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((post) => {
              this.currentConfirmationSMSPostSetting = post;
              this.getPreview();
            });
        } else if (type === 'ARRIVAL') {
          this.defaultService
            .updateSetting('ARRIVAL_SMS_POST_MESSAGE', {
              ...this.currentArrivalSMSPostSetting,
              value: this.postText.value,
            })
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((post) => {
              this.currentArrivalSMSPostSetting = post;
              this.getPreview();
            });
        }
        break;
    }
  }

  reset(field: 'PRE' | 'POST') {
    switch (field) {
      case 'PRE':
        this.preTextChanged = false;
        this.preText.setValue(
          (this.currentSMSType === 'CONFIRM'
            ? this.currentConfirmationSMSPreSetting.value
            : this.currentArrivalSMSPreSetting.value) || '',
          {
            emitEvent: false,
          }
        );
        break;
      case 'POST':
        this.postTextChanged = false;
        this.postText.setValue(
          (this.currentSMSType === 'CONFIRM'
            ? this.currentConfirmationSMSPostSetting.value
            : this.currentArrivalSMSPostSetting.value) || '',
          {
            emitEvent: false,
          }
        );
        break;
    }
  }

  countSMS(): number {
    const totalTextLength = this.message.length;
    return totalTextLength <= 160 ? 1 : Math.ceil(totalTextLength / 153);
  }
}
