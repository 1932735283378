import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DefaultService, OrderDTO } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  readonly onDestroy$ = new Subject();
  orderId: number | undefined;
  paymentId: number | undefined;
  klarnaSnippet!: string;
  order!: OrderDTO;

  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly orderService: DefaultService
  ) {}
  
  ngOnInit(): void {
    let temp = this.activatedRoute.snapshot.queryParamMap.get('id');
    let temp2 = this.activatedRoute.snapshot.queryParamMap.get('paymentid');
    
    if (temp && temp2) {
      this.orderId = parseInt(temp, 10);
      this.paymentId = parseInt(temp2, 10);
      this.getOrder();
    } else if (temp) {
      this.orderId = parseInt(temp, 10);
      this.getOrder();
    }
  }

  getOrder() {
    if (this.paymentId) {
      this.orderService.readOrderPrivate(this.paymentId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(orderResponse => {
          if (orderResponse.htmlSnippet) { 
            this.klarnaSnippet = orderResponse.htmlSnippet;

            if (this.orderId) {
              this.orderService.getOrderPrivate(this.orderId)
                .pipe(takeUntil(this.onDestroy$))
                .subscribe(order => {
                  this.order = order;
                })
            }
          }
        });
    } else {
      // No payment, fetch order
      if (this.orderId) {
        this.orderService.getOrderPrivate(this.orderId)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(order => {
            this.order = order;
          });
      }
}
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }
}
