import { Injectable } from '@angular/core';
import { Observable, forkJoin, of, tap } from 'rxjs';
import { AddOrderItem, DeleteOrderItem } from '../states/orders/orders.action';
import { Store } from '@ngxs/store';
import { BookingDTO, FeatureDTO, OrderDTO, OrderItemDTO } from 'parking-sdk';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(readonly store: Store) {}

  updateOrderFeatures(
    currentBooking: BookingDTO,
    selectedFeatures: FeatureDTO[],
    editOrder: OrderDTO | undefined
  ): Observable<any> {
    /*
     * If selected has feature that order does not have, add item.
     * If order items has feature that selected does not have, remove item.
     * Always check that the feature has the same booking.bookingId as currentBooking
     */

    /* Save all dispatches in array */
    let dispatchObservables: Observable<any>[] = [];

    if (selectedFeatures.length > 0) {
      /* Add new features */

      selectedFeatures.forEach((feature) => {
        /* Check that feature does not already exist */
        let existingFeature = editOrder?.orderItems?.find(
          (item) =>
            item.orderItemType === 'FEATURE' &&
            item.feature?.featureId === feature.featureId &&
            item.booking?.bookingId &&
            item.booking?.bookingId === currentBooking.bookingId
        );

        if (!existingFeature) {
          /* Add item */
          dispatchObservables.push(
            this.store.dispatch(
              new AddOrderItem({
                orderItemType: 'FEATURE',
                feature: feature,
              })
            )
          );
        }
      });

      /* Remove unselected features */
      editOrder?.orderItems?.forEach((item) => {
        if (item.orderItemType == 'FEATURE') {
          let unselectedFeature = selectedFeatures.find(
            (feature) => feature.featureId === item.feature?.featureId
          );

          if (
            !unselectedFeature &&
            editOrder?.orderId &&
            item.orderItemId &&
            item.booking?.bookingId &&
            item.booking?.bookingId === currentBooking.bookingId
          ) {
            /* Remove item */
            dispatchObservables.push(
              this.store.dispatch(
                new DeleteOrderItem(editOrder.orderId, item.orderItemId)
              )
            );
          }
        }
      });
    } else {
      /* No selected features, delete all features */
      editOrder?.orderItems?.forEach((item) => {
        if (
          editOrder?.orderId &&
          item.orderItemType === 'FEATURE' &&
          item.orderItemId &&
          item.booking?.bookingId &&
          item.booking?.bookingId === currentBooking.bookingId
        ) {
          dispatchObservables.push(
            this.store.dispatch(
              new DeleteOrderItem(editOrder.orderId, item.orderItemId)
            )
          );
        }
      });
    }

    if (dispatchObservables.length === 0) {
      // If no action was dispatched, return observable anyway
      return of(0);
    } else {
      return forkJoin(dispatchObservables).pipe(
        tap({
          next: () => {},
          error: () => console.error('Something went wrong updating features.'),
        })
      );
    }
  }

  addOrderFeatures(selectedFeatures: FeatureDTO[]): Observable<any> {
    let dispatchObservables: Observable<any>[] = [];

    if (selectedFeatures.length) {
      selectedFeatures.forEach((feature) => {
        dispatchObservables.push(
          this.store.dispatch(
            new AddOrderItem({
              orderItemType: 'FEATURE',
              feature: feature,
            })
          )
        );
      });
    }
    return forkJoin(dispatchObservables).pipe(
      tap({
        next: () => {},
        error: () =>
          console.error('Something went wrong when adding features.'),
      })
    );
  }

  allOrderBookingsCancelled(orderItems: OrderItemDTO[]): boolean {
    const allOrderBookings = orderItems.filter(
      (item) => item.orderItemType === 'RESOURCE'
    );
    return allOrderBookings.length
      ? allOrderBookings.every(
          (item) => item.booking?.bookingStatus === 'CANCELLED'
        )
      : false;
  }
}
