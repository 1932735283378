<!-- Searchbox -->
<section class="searchbox-wrapper">
  <input type="search" placeholder="Reg.nr/bokningsnr." [formControl]="searchInput" #searchBox
    (input)="search(searchInput.value || undefined)" data-test="search-in-list" spellcheck="false" autocomplete="off" />
</section>

<div class="search-result-wrapper">
  <app-bookings-table *ngIf="searchResult && !termNotFound" [bookings]="searchResult" [topic]="currentDirection"
    [coloredSearchResult]="true"></app-bookings-table>

  <!-- Booking not found -->
  <div class="empty-content empty-content-colored" *ngIf="termNotFound && !searchResult">
    <p>Kunde inte hitta en bokning med "{{ termNotFound }}"</p>
  </div>
  
  <!-- No search result -->
  <div class="empty-content empty-content-colored" *ngIf="!termNotFound && !searchResult">
    <p>Sök på registrerings- eller bokningsnummer för att filtrera i {{currentDirection === 'arrival' ? 'hämtlistan': 'bokningslistan'}}. </p>
  </div>

  <div class="spinner-wrapper" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
  </div>
</div>