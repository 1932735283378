import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import dayjs from 'dayjs';
import { DefaultService, ResourceDayDTO } from 'parking-sdk';
import { LineChartDataset } from 'rely-statistics-sdk';
import { Subject, takeUntil } from 'rxjs';
import { ExportService } from 'src/app/core/services/export.service';

@Component({
  selector: 'app-occupancy-report',
  templateUrl: './occupancy-report.component.html',
  styleUrls: ['./occupancy-report.component.scss'],
})
export class OccupancyReportComponent implements OnInit {
  from = new FormControl<Date | undefined>(dayjs().hour(0).minute(0).toDate());
  to = new FormControl<Date | undefined>(
    dayjs().add(1, 'week').hour(0).minute(0).toDate()
  );
  resourceType: 'garage' | 'outside' = 'outside';
  errorMessage = false;

  chartTitles: string[] = [];
  chartData: LineChartDataset[] = [];
  loading = true;
  occupancyReport: ResourceDayDTO[] = [];

  readonly onDestroy$ = new Subject<void>();

  constructor(
    readonly defaultService: DefaultService,
    readonly exportService: ExportService
  ) {}

  ngOnInit(): void {
    this.getOccupancyReport();

    this.from.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      if (this.to.value) {
        this.to.setValue(undefined, {
          emitEvent: false,
        });
      }
      this.from.value && this.to?.value && this.getOccupancyReport();
    });

    this.to?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        () => this.from.value && this.to?.value && this.getOccupancyReport()
      );
  }

  changeResourceType(value: 'garage' | 'outside') {
    this.resourceType = value;
    this.getOccupancyReport();
  }

  getOccupancyReport() {
    if (this.from.value && this.to.value) {
      this.loading = true;
      this.chartData = [];
      this.chartTitles = [];

      let type = 1;
      if (this.resourceType === 'outside') {
        type = 3;
      }

      this.defaultService
        .getResourceStatusAdmin(
          dayjs(this.from.value).second(0).millisecond(0).toDate(),
          dayjs(this.to.value).second(0).millisecond(0).add(1, 'day').toDate()
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: (data) => {
            this.occupancyReport = data;
            let arrivalData: number[] = [],
              departureData: number[] = [],
              totalData: number[] = [];

            data.forEach((val) => {
              this.chartTitles.push(formatDate(val.date!, 'd MMM', 'sv-SE'));
              arrivalData.push(val.allocations![type].arrivalQty!);
              departureData.push(val.allocations![type].departureQty!);
              totalData.push(val.allocations![type].occupiedQty!);
            });

            this.chartData.push({
              data: arrivalData,
              name: 'Ankommande',
              type: 'line',
            });

            this.chartData.push({
              data: departureData,
              name: 'Avgående',
              type: 'line',
            });

            this.chartData.push({
              data: totalData,
              name: 'Fyllda platser',
              type: 'line',
            });

            this.loading = false;
            this.errorMessage = false;
          },
          error: (error) => {
            console.error('Error fetching occupancy report: ', error);
            this.errorMessage = true;
          },
        });
    }
  }

  downloadReportBookings() {
    if (this.from.value && this.to.value)
      this.exportService.getReportBookings(
        dayjs(this.from.value).second(0).millisecond(0).toDate(),
        dayjs(this.to.value).second(0).millisecond(0).add(1, 'day').toDate()
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
