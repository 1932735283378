import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PartnerDTO, RoleDTO, UserDTO } from 'parking-sdk';
import { Subject, Observable, takeUntil } from 'rxjs';
import { DefaultService } from 'parking-sdk';
import {
  GetRoles,
  RemoveUser,
  UpdateUser,
} from 'src/app/core/states/users/user.actions';
import { UsersState } from 'src/app/core/states/users/users.state';
import { Store } from '@ngxs/store';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  readonly onDestroy$ = new Subject<void>();
  editUser?: UserDTO;
  editUserForm?: FormGroup;
  partners?: PartnerDTO[];
  selectedPartner?: PartnerDTO;
  roleOptions$: Observable<RoleDTO[]>;

  constructor(
    readonly dialog: MatDialog,
    public dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user?: UserDTO },
    readonly store: Store,
    public defaultService: DefaultService,
    readonly fb: FormBuilder,
    readonly snackBar: MatSnackBar
  ) {
    // Copy the user to avoid modifying the original in the parent component
    this.editUser = data?.user ? { ...data.user } : undefined;
    this.roleOptions$ = this.store.select(UsersState.currentRoles);
  }

  ngOnInit() {
    this.editUserForm = this.fb.group({
      username: [
        this.editUser?.username,
        [Validators.required, Validators.email],
      ],
      roles: [this.editUser?.roles, Validators.required],
      newPassword: [this.editUser?.newPassword, Validators.minLength(6)],
      partner: [this.getPartner()],
    });

    this.isPartner() && this.getPartners();
    this.store.dispatch(GetRoles);
    this.selectedPartner = this.getPartner();

    /* Listen to changes on roles */
    this.editUserForm.controls['roles'].valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((changes: any[]) => {
        if (changes?.some((change) => change.name === 'ROLE_PARTNER')) {
          this.getPartners();
        } else {
          this.partners = undefined;
          this.editUserForm?.controls['partner'].setValue(undefined);
        }
      });
  }

  isPartner(): boolean {
    return (
      this.editUser?.roles?.some((role) => role.name === 'ROLE_PARTNER') ||
      false
    );
  }

  compareSelected(a: RoleDTO, b: RoleDTO) {
    return a?.id === b?.id;
  }

  compareSelectedPartner(a: PartnerDTO, b: PartnerDTO) {
    return a?.partnerId === b?.partnerId;
  }

  // Force reset password for user next time they log in
  resetPassword() {
    this.defaultService
      .requestPasswordResetAdmin({ email: this.editUser?.username })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: () => {
          this.snackBar.open('Återsällningsmejl skickat!', 'OK', {
            duration: 3000,
          });
        },
        error: (error) => {
          console.error('Error when resetting password: ', error);
          this.dialog.open(ErrorDialogComponent, {
            data: {
              title:
                'Det gick inte att skicka återställningsmejl till användaren',
              message: [
                'Var god kontrollera mejladressen eller prova igen om en liten stund.',
              ],
            },
          });
        },
      });
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSaveClick() {
    if (this.editUserForm?.valid) {
      /* If empty password, set undefined. Backend will send email to user with password link if password is undefined. */
      let newPassword = this.editUserForm.get('newPassword')?.value;
      if (newPassword && newPassword.trim() === '') {
        newPassword = undefined;
      }

      /* If role is partner, connect partner obj to role */
      let roles: RoleDTO[] = this.editUserForm.get('roles')?.value;
      if (this.editUserForm.controls['partner'].value) {
        roles = roles.map((role) => {
          if (role.name === 'ROLE_PARTNER') {
            return {
              ...role,
              partner: this.editUserForm?.controls['partner'].value,
            };
          } else return role;
        });
      }

      if (this.editUser?.id) {
        /* Update user */

        const updatedUser: UserDTO = {
          id: this.editUser.id,
          username: this.editUserForm.value.username,
          roles: roles,
          newPassword: newPassword,
        };

        this.store
          .dispatch(new UpdateUser(this.editUser.id, updatedUser))
          .pipe(takeUntil(this.onDestroy$))
          .subscribe({
            next: (response) => {
              this.snackBar.open('Användare uppdaterad', 'OK', {
                duration: 3000,
              });
            },
            error: (error) => {
              console.error('Error updating user: ', error);
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  title: 'Det gick inte att uppdatera användaren',
                  message: ['Var god prova igen om en liten stund.'],
                },
              });
            },
          });
        this.dialogRef.close();
      } else {
        /* Return user to create */
        this.dialogRef.close({
          username: this.editUserForm.get('username')?.value,
          roles: roles,
          newPassword: newPassword,
        });
      }
    } else {
      console.error('User form not valid');
    }
  }

  onDeleteClick() {
    let userIdToDelete = this.editUser?.id;
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Är du säker på att du vill ta bort användare "${this.editUser?.username}"?`,
        message: [`Det kommer inte gå att återställa kontot.`],
      },
    });
    confirmDialog
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result && userIdToDelete) {
          this.store
            .dispatch(new RemoveUser(userIdToDelete))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
              next: (response) => {
                this.snackBar.open('Användare borttagen', 'OK', {
                  duration: 3000,
                });
              },
              error: (error) => {
                console.error('Error removing user: ', error);
                this.dialog.open(ErrorDialogComponent, {
                  data: {
                    title: 'Det gick inte att radera användaren',
                    message: ['Var god prova igen om en liten stund.'],
                  },
                });
              },
            });
          this.dialogRef.close();
        }
      });
  }

  getPartner(): PartnerDTO | undefined {
    return (
      this.editUser?.roles?.find((role) => role.partner)?.partner || undefined
    );
  }

  getPartners(): void {
    this.defaultService
      .findPartners()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((partners) => {
        this.partners = partners;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
