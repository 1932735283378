import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subject, takeUntil } from 'rxjs';
import { DrawerServiceService } from 'src/app/core/services/drawer-service.service';
import { OrdersState } from 'src/app/core/states/orders/orders.state';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
  currentContent?: string;
  activeOrder = false;
  @Input() drawerActive: boolean = false;
  @Output() toggleDrawer: EventEmitter<any> = new EventEmitter();
  @Output() closeDrawer: EventEmitter<any> = new EventEmitter();
  readonly onDestroy$ = new Subject<void>();

  constructor(
    readonly drawerService: DrawerServiceService,
    readonly store: Store,
    router: Router
  ) {
    drawerService.currentContent
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.currentContent = value;
      });

    /* Close drawer if navigating away */
    router.events.pipe(takeUntil(this.onDestroy$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.close();
      }
    });
  }

  ngOnInit() {
    this.store
      .select(OrdersState.order)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((order) => {
        this.activeOrder = !!order?.orderId;
      });
  }

  ngOnChanges() {
    this.store
      .select(OrdersState.order)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((order) => {
        this.activeOrder = !!order?.orderId;
      });
  }

  close() {
    this.closeDrawer.emit();
  }

  changeDrawer(content: string) {
    if (this.currentContent === content) {
      this.toggleDrawer.emit();
    } else if (this.drawerActive) {
      this.drawerService.changeContent(content);
    } else {
      this.drawerService.changeContent(content);
      this.toggleDrawer.emit();
    }
  }
}
