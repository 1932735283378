import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  BookingDTO,
  CashDrawerActionDTO,
  DefaultService,
  OrderDTO,
} from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { ReceiptComponent } from 'src/app/components/receipt/receipt.component';
import { IframeService } from 'src/app/core/services/iframe.service';
import { OrderService } from 'src/app/core/services/order.service';
import { GetOrder } from 'src/app/core/states/orders/orders.action';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
})
export class OrderSummaryComponent implements OnDestroy {
  @ViewChild('iframesummary', { read: ElementRef, static: false })
  iframeSummary: ElementRef | undefined;
  @ViewChild('iframereceipt', { read: ElementRef, static: false })
  iframeReceipt: ElementRef | undefined;
  @Input() order?: OrderDTO;
  @Input() currentBooking?: BookingDTO;
  @Input() hasUnfinishedPayments = false;
  @Output() onGetBooking: EventEmitter<number> = new EventEmitter();
  doc: any;
  zeroPayment = false;
  dynamicRef?: ComponentRef<ReceiptComponent>;
  cancelledBookings = false;
  emailMsg?: string;
  emailSent?: boolean;
  loading = false;
  readonly onDestroy$ = new Subject<void>();

  constructor(
    readonly router: Router,
    readonly defaultService: DefaultService,
    readonly store: Store,
    readonly viewContainerRef: ViewContainerRef,
    readonly iframeService: IframeService,
    readonly cdr: ChangeDetectorRef,
    readonly dialog: MatDialog,
    readonly orderService: OrderService
  ) {}

  ngOnInit() {
    this.cancelledBookings = this.order?.orderItems
      ? this.orderService.allOrderBookingsCancelled(this.order.orderItems)
      : false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cancelledBookings = this.order?.orderItems
      ? this.orderService.allOrderBookingsCancelled(this.order.orderItems)
      : false;
      
    if (
      this.order &&
      this.havePrepaidTicket(this.order) &&
      this.order.orderStatus === 'COMPLETED'
    ) {
      // Show receipt button
      this.zeroPayment = true;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.order = undefined;
  }

  calculateTotalDiscount(): number {
    if (this.order)
      return (
        (this.order.totalAmountWithoutDiscount || 0) -
        (this.order.totalAmount || 0)
      );
    else return 0;
  }

  goToCheckout(): void {
    if (this.order?.orderId !== undefined)
      this.store
        .dispatch(new GetOrder(this.order.orderId))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((v) => this.router.navigate(['hantera-betalning']));
  }

  getBooking(event: number) {
    this.onGetBooking.emit(event);
  }

  onLoad() {
    if (this.iframeSummary) {
      this.doc = this.iframeSummary.nativeElement.contentWindow.document;
      this.createComponent();
    } else {
      setTimeout(() => {
        this.doc = this.iframeSummary!.nativeElement.contentWindow.document;
        this.createComponent();
      });
    }
  }

  reloadReceipt() {
    if (this.dynamicRef) {
      this.dynamicRef.instance.order = this.order;
      this.dynamicRef.changeDetectorRef.detectChanges();
    }
  }


  createComponent() {
    this.dynamicRef = this.viewContainerRef.createComponent(ReceiptComponent);
    this.dynamicRef.instance.order = this.order;
    this.dynamicRef.changeDetectorRef.detectChanges();
    this.doc.body.appendChild(this.dynamicRef.location.nativeElement);
    this.iframeService.attachStyles(this.doc);
  }

  awaitReceiptChanges(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.cdr.detectChanges();
      setTimeout(() => {
        resolve();
      }, 0);
    });
  }

  printReceipt() {
    let cashDrawerAction: CashDrawerActionDTO = {
      cashDrawerActionType: 'OTHER',
      comment: 'COPY',
    };

    cashDrawerAction &&
      this.defaultService
        .addCashDrawerActionAdmin(cashDrawerAction)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe();

    let latestPayment =
      (this.order?.payments?.length &&
        this.order.payments[this.order.payments.length - 1]) ||
      undefined;

    latestPayment?.paymentId &&
    this.defaultService
    .getPaymentReceiptHtmlAdmin(latestPayment.paymentId)
    .pipe(takeUntil(this.onDestroy$))
    .subscribe({
      next: (v) => {
        if (v.html) {
          if (this.iframeReceipt) {
            this.iframeReceipt.nativeElement.contentWindow.document.open();
            this.iframeReceipt.nativeElement.contentWindow.document.close();
            this.iframeReceipt.nativeElement.contentWindow.frames.document.body.insertAdjacentHTML(
              'afterbegin',
              v.html
            );
            this.awaitReceiptChanges().then(() => {
              this.iframeReceipt?.nativeElement.contentWindow.print();
            });
          }
        }
      },
      error: (error) => {
        console.error('Error fetching receipt: ', error);

        this.dialog.open(ErrorDialogComponent, {
          data: {
            title: 'Det gick inte att skriva ut kvittot',
            message: ['Kvittot kunde inte hittas i kassasystemet.'],
          },
        });
      },
    });
  }

  printSummary() {
    (
      document.getElementById('iframesummary') as HTMLIFrameElement
    ).contentWindow?.print();
  }

  havePrepaidTicket(order: OrderDTO): boolean {
    const prepaidTicketOrderItems = order.orderItems?.filter(
      (orderItem) =>
        orderItem.orderItemType === 'RESOURCE' &&
        orderItem.booking &&
        orderItem.booking.prepaidTicket
    );
    return prepaidTicketOrderItems!.length > 0;
  }

  hasOnlyPaymentType(type: 'KLARNA' | 'INVOICE'): boolean {
    return this.order?.payments
      ? this.order.payments?.every(
          (payment) => payment.paymentMethod?.paymentMethodId === type
        )
      : false;
  }

  hasPaymentType(type: 'KLARNA' | 'INVOICE'): boolean {
    return this.order?.payments
      ? this.order.payments?.some(
          (payment) => payment.paymentMethod?.paymentMethodId === type
        )
      : false;
  }

  sendKlarnaReceipt() {
    const confDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Är du säker på att du vill skicka en Klarna-kvittokopia till kunden?`,
        message: [
          `Kunden kommer att få en Klarna-kvittokopia till den angivna e-postadressen i bokningen.`,
        ],
        yesBtnText: 'Skicka',
      },
    });

    confDialog
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          if (this.order?.payments) {
            this.loading = true;
            this.emailMsg = undefined;
            this.order.payments?.forEach(
              (payment) =>
                payment.paymentMethod?.paymentMethodId === 'KLARNA' &&
                payment.paymentId &&
                this.defaultService
                  .sendPaymentReceiptAdmin(payment.paymentId)
                  .pipe(takeUntil(this.onDestroy$))
                  .subscribe({
                    next: () => {
                      this.emailSent = true;
                      this.emailMsg = 'Kvitto skickat!';
                      this.loading = false;
                    },
                    error: () => {
                      this.emailSent = false;
                      this.emailMsg =
                        'Det gick inte att skicka mejlet, kontrollera e-postadressen.';
                      this.loading = false;
                    },
                  })
            );
          } else {
            this.emailSent = false;
            this.emailMsg =
              'Det gick inte att skicka bokningsbekräftelsen, ladda om sidan och försök igen.';
          }
        }
      });
  }
}
