import { Component } from '@angular/core';
import { AdvancePaymentReportDTO, DefaultService } from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { ExportService } from 'src/app/core/services/export.service';

@Component({
  selector: 'app-advance-payments-report',
  templateUrl: './advance-payments-report.component.html',
  styleUrls: ['./advance-payments-report.component.scss'],
})
export class AdvancePaymentsReportComponent {
  advancePaymentReport?: AdvancePaymentReportDTO[];
  errorMessage = false;

  readonly onDestroy$ = new Subject<void>();

  constructor(
    readonly defaultService: DefaultService,
    readonly exportService: ExportService
  ) {}

  ngOnInit() {
    this.defaultService
      .getAdvancePaymnetReport()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (value) => {
          this.advancePaymentReport = value;
          this.errorMessage = false;
        },
        error: (error) => {
          console.error('Error when fetching advance payment report: ', error);
          this.errorMessage = true;
        },
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  downloadReport() {
    this.exportService.getReportAdvancePayments();
  }

  downloadReportDetails() {
    this.exportService.getReportAdvancePaymentDetails();
  }
}
