import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ApiModule, BASE_PATH, Configuration, ConfigurationParameters, DefaultService } from 'parking-sdk';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RelyitModule } from '../pages/relyit/relyit.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizationInterceptor } from './interceptors/authorization-interceptor';


export function apiConfigFactory () : Configuration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
  }
  return new Configuration(params);
}


@NgModule({ declarations: [], imports: [CommonModule,
        SharedModule,
        ApiModule,
        RelyitModule], providers: [
        { provide: BASE_PATH, useValue: environment.backendServerUrl },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        `CoreModule has already been loaded. Import Core modules in the AppModule only.`
      );
    }
  }
}
