import {
  Component,
  ElementRef,
  Inject,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { DefaultService, FlightBookingDTO, HourBookingDTO } from 'parking-sdk';
import { DateService } from 'src/app/core/services/date.service';
import { BookingsState } from 'src/app/core/states/bookings/bookings.state';
import { CurrentBookingsComponent } from '../current-bookings/current-bookings.component';
import { IframeService } from 'src/app/core/services/iframe.service';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-print-pickup-list',
  templateUrl: './print-pickup-list.component.html',
  styleUrls: ['./print-pickup-list.component.scss'],
})
export class PrintPickupListComponent {
  timeRangeForm = new FormGroup({
    date: new FormControl(),
    startTime: new FormControl(),
    endTime: new FormControl(),
  });
  from?: Date;
  to?: Date;
  fromLaterThanTo: boolean = false;
  groupedBookings: FlightBookingDTO[] | HourBookingDTO[] = [];
  @ViewChild('pickupListFrame', { read: ElementRef, static: false }) pickupListFrame: ElementRef | undefined;
  doc: any;
  selectBookings = new Observable<FlightBookingDTO[] | HourBookingDTO[]>();
  findBookings = new Observable<FlightBookingDTO[] | HourBookingDTO[]>();
  showSpinner: boolean = false;
  onDestroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<PrintPickupListComponent>,
    readonly defaultService: DefaultService,
    readonly store: Store,
    public dateService: DateService,
    readonly viewContainerRef: ViewContainerRef,
    readonly iframeService: IframeService,
    @Inject(MAT_DIALOG_DATA) public data?: { currentTopic: 'arrival' | 'departure' }
  ) {
    this.selectBookings =
      this.data?.currentTopic === 'arrival'
        ? this.store.select(BookingsState.currentArrivalBookings)
        : this.store.select(BookingsState.currentDepartureBookings);
  }

  ngOnInit() {
    this.selectBookings.subscribe((v) => {
      this.groupedBookings = v;
    });
    this.store.select(BookingsState.upcomingTimeRange).pipe(takeUntil(this.onDestroy$)).subscribe((v) => {
      if (v) {
        this.timeRangeForm.controls.date.setValue(
          this.dateService.formatDate(v.fromHour)
        );

        this.timeRangeForm.controls.startTime.setValue(
          this.dateService.formatTime(v.fromHour)
        );

        this.timeRangeForm.controls.endTime.setValue(
          this.dateService.formatTime(v.toHour)
        );

        this.from = v.fromHour;
        this.to = v.toHour;
      }
    });
  }

  onFrameLoad() {
      setTimeout(() => {
        // Make sure it ends up first in queue and runs as soon as possible  when the call stack is empty
        // https://stackoverflow.com/questions/9083594/call-settimeout-without-delay
        this.doc = this.pickupListFrame!.nativeElement.contentWindow.document;
        this.createComponent();
      });
  }

  createComponent() {
    this.viewContainerRef.clear();
    const component = this.viewContainerRef.createComponent(
      CurrentBookingsComponent
    );
    component.setInput('groupedBookings', this.groupedBookings);
    component.setInput('printGroups', true);
    component.location.nativeElement.id = 'printbookings';

    this.doc.body.appendChild(component.location.nativeElement);
    this.iframeService.attachStyles(this.doc);
    this.iframeService.addTitle(
      this.doc,
      this.data?.currentTopic === 'arrival' ? 'Hämtlista' : 'Lämnlista'
    );
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  printIframe() {
    if (this.pickupListFrame?.nativeElement.contentWindow)
      this.pickupListFrame.nativeElement.contentWindow.print();
  }

  reloadIframeAndPrint() {
    this.findBookings =
      this.data?.currentTopic === 'arrival'
        ? this.defaultService.findCurrentFlights('ARRIVAL', this.from, this.to)
        : this.defaultService.findCurrentDepartureBookings(this.from, this.to);

    this.showSpinner = true;
    this.findBookings.pipe(takeUntil(this.onDestroy$)).subscribe((v) => {
      this.groupedBookings = v;
      this.createComponent();
      this.showSpinner = false;
      setTimeout(() => {
        // Make sure it ends up first in queue and runs as soon as possible when the call stack is empty
        this.printIframe();
      });
    });
  }

  checkDateRange(){
    this.from = this.dateService.composeDatetime(
      this.timeRangeForm.controls.date.value,
      this.timeRangeForm.controls.startTime.value
    );
    this.to = this.dateService.composeDatetime(
      this.timeRangeForm.controls.date.value,
      this.timeRangeForm.controls.endTime.value
    );

    if(this.from && this.to && this.from >= this.to){ 
      this.to.setDate(this.to.getDate() + 1);
      this.fromLaterThanTo = true;
      return;
    }
    this.fromLaterThanTo = false;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
