import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngxs/store';
import { PrepaidTicketDTO } from 'parking-sdk';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { PrepaidCreateComponent } from 'src/app/components/prepaid-create/prepaid-create.component';
import { AddOrderItem } from 'src/app/core/states/orders/orders.action';
import { OrdersState } from 'src/app/core/states/orders/orders.state';
import { GetPrepaidTickets } from 'src/app/core/states/prepaid/prepaid.actions';
import { PrepaidState } from 'src/app/core/states/prepaid/prepaid.state';

@Component({
  selector: 'app-prepaid',
  templateUrl: './prepaid.component.html',
  styleUrls: ['./prepaid.component.scss'],
})
export class PrepaidComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource<PrepaidTicketDTO>();
  prepaidTickets$: Observable<PrepaidTicketDTO[]> = this.store.select(
    PrepaidState.currentTickets
  );
  sortedData!: PrepaidTicketDTO[];
  inactiveFilter = false;
  activeOrder = false;

  readonly onDestroy$ = new Subject<void>();

  constructor(readonly dialog: MatDialog, readonly store: Store) {
    this.prepaidTickets$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((tickets) => (this.sortedData = tickets));
  }

  ngOnInit() {
    this.toggleInactive(false);
    this.store
      .dispatch(GetPrepaidTickets)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe();
    this.store
      .select(OrdersState.order)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((order) => (this.activeOrder = !!order.orderId));
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  toggleInactive(showInactive: boolean) {
    this.prepaidTickets$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((tickets) => {
        if (!showInactive) {
          this.dataSource.data = tickets.filter((ticket) => ticket.active);
        } else {
          this.dataSource.data = tickets;
        }
      });
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'owner':
          return this.compare(a.name!, b.name!, isAsc);
        case 'from':
          return this.compare(a.validFrom!, b.validFrom!, isAsc);
        case 'to':
          return this.compare(a.validTo!, b.validTo!, isAsc);
        default:
          return 0;
      }
    });

    this.dataSource.data = data;
  }

  searchTicket(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
      .trim()
      .toLowerCase();
    if (filterValue === '') {
      this.prepaidTickets$ = this.store.select(PrepaidState.currentTickets);
    } else {
      this.prepaidTickets$ = this.store
        .select(PrepaidState.currentTickets)
        .pipe(
          map((tickets) =>
            tickets.filter((ticket: PrepaidTicketDTO) =>
              ticket.name?.toLowerCase().includes(filterValue)
            )
          )
        );
    }
    this.prepaidTickets$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => (this.dataSource.data = result));
  }

  createPrepaidTicket() {
    const dialogRef = this.dialog.open(PrepaidCreateComponent, {
      width: '600px',
      height: '75vh',
      data: { create: true },
    });
    
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result: PrepaidTicketDTO) => {
        if (result) {
          this.store.dispatch(
            new AddOrderItem({
              orderItemType: 'PREPAIDTICKET',
              prepaidTicket: result,
            })
          );
        }
      });
  }

  editPrepaidTicket(ticket: PrepaidTicketDTO) {
    const dialogRef = this.dialog.open(PrepaidCreateComponent, {
      width: '600px',
      height: '75vh',
      data: { create: false, ticket: ticket },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) this.store.dispatch(GetPrepaidTickets);
      });
  }

  hasExpired(date: Date) {
    if (new Date().getTime() > new Date(date).getTime()) {
      return true;
    }
    return false;
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    if (typeof a === 'string' && typeof b === 'string') {
      a = a.toUpperCase();
      b = b.toUpperCase();
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
