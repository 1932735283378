import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BookingDTO, OrderDTO } from 'parking-sdk';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.scss']
})
export class OrderItemsComponent {
  @Input() order?: OrderDTO
  @Input() currentBooking?: BookingDTO
  @Input() renderType: 'order' | 'booking' = 'booking'
  @Output() onGetBooking: EventEmitter<number> = new EventEmitter()
  readonly onDestroy$ = new Subject<void>();

  constructor(readonly router: Router){}

  showBooking(id: number | undefined) {
    if (id !== undefined) {
      this.onGetBooking.emit(id);
      this.router.navigate(['bokning', id]);
    }
  }
  
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.order = undefined;
  }
}
