import { Injectable } from '@angular/core';
import { State, StateContext, Action, Selector } from '@ngxs/store';
import { DefaultService, FeatureDTO } from 'parking-sdk';
import * as Actions from './feature.action';
import { tap } from 'rxjs';

export interface FeaturesStateModel {
  features: FeatureDTO[];
}

@State<FeaturesStateModel>({
  name: 'features',
  defaults: {
    features: [],
  },
})
@Injectable()
export class FeaturesState {
  constructor(readonly defaultService: DefaultService) {}

  @Selector()
  static features(state: FeaturesStateModel) {
    return state.features;
  }

  @Action(Actions.GetFeatures)
  getFeatures(context: StateContext<FeaturesStateModel>) {
    // Wait for David to add enpoint that fetches all features. This only gets the main features. See also findFeatures endpoint.
    return this.defaultService.getFeaturesAdmin().pipe(
      tap((data) => {
        context.patchState({ features: data });
      })
    );
  }
}
