import { Component } from '@angular/core';
import { DefaultService, OrderDTO, PaymentDTO } from 'parking-sdk';
import { DateService } from 'src/app/core/services/date.service';
import { PageEvent } from '@angular/material/paginator';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { Subject, debounceTime, map, takeUntil } from 'rxjs';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { OrderSearchListener } from 'src/app/core/states/orders/orders.action';
import dayjs from 'dayjs';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent {
  orders?: OrderDTO[];
  displayedColumns?: string[];
  searchAndFilterForm?: FormGroup;
  searchInput = new FormControl<string | undefined>({
    value: undefined,
    disabled: false,
  });
  readonly onDestroy$ = new Subject<void>();

  /* Paginator */
  pageSize: number = 50;
  pageIndex: number = 0;
  totalOrders: number = 0;

  constructor(
    readonly defaultService: DefaultService,
    readonly dateService: DateService,
    readonly formBuilder: FormBuilder,
    readonly router: Router,
    readonly store: Store,
    actions$: Actions
  ) {
    actions$
      .pipe(
        ofActionDispatched(OrderSearchListener),
        map((action: OrderSearchListener) => action.term),
        debounceTime(300),
        takeUntil(this.onDestroy$)
      )
      .subscribe((term) => {
        this.search(term);
      });
  }

  ngOnInit() {
    this.displayedColumns = [
      'orderNumber',
      'bookings',
      'prepaid',
      'features',
      'paymentStatus',
      'paymentMethod',
      'createdDate',
      'updatedDate',
      'totalAmount',
    ];

    const now = new Date();
    const oneYearBack = new Date(
      now.getFullYear() - 1,
      now.getMonth(),
      now.getDate()
    );

    /* Create form for date range */
    this.searchAndFilterForm = this.formBuilder.group({
      from: new FormControl<Date | undefined>(oneYearBack),
      to: new FormControl<Date | undefined>(now),
      paymentStatus: new FormControl<string>('all'),
      paymentMethod: new FormControl<string>('all'),
    });

    this.getOrders();

    /* Listen to input changes */
    this.searchAndFilterForm
      ?.get('from')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.getOrders();
      });
    this.searchAndFilterForm
      ?.get('to')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.getOrders();
      });
    this.searchAndFilterForm
      ?.get('paymentStatus')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.getOrders();
      });
    this.searchAndFilterForm
      ?.get('paymentMethod')
      ?.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.getOrders();
      });

    this.searchInput.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((v) => {
        v && this.store.dispatch(new OrderSearchListener(v));
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  uniquePaymentMethods(payments: PaymentDTO[]) {
    return [...new Set(payments.map((item) => item.paymentMethod!.label!))];
  }

  showOrder(row: any) {
    /* Open single page order */
    row?.orderId && this.router.navigate(['order', row?.orderId]);
  }

  getOrders(sorting?: 'asc' | 'desc') {
    const now = new Date();
    const oneMonthBack = new Date(now.getFullYear(), now.getMonth() - 1, 1);

    let paymentMethod =
      this.searchAndFilterForm?.controls['paymentMethod']?.value;
    if (paymentMethod === 'all') paymentMethod = undefined;

    let paymentStatus =
      this.searchAndFilterForm?.controls['paymentStatus']?.value;
    if (paymentStatus === 'all') paymentStatus = undefined;

    let searchTerm = this.searchInput.value;

    if (
      this.searchAndFilterForm?.controls['from'].value &&
      this.searchAndFilterForm?.controls['to'].value
    ) {
      this.defaultService
        .getOrdersAdmin(
          this.pageIndex,
          this.pageSize,
          'createdDate,' + (sorting || 'desc'),
          undefined,
          undefined,
          undefined,
          undefined,
          this.searchAndFilterForm?.controls['from'].value || oneMonthBack,
          dayjs(this.searchAndFilterForm?.controls['to'].value)
            .add(1, 'day')
            .toDate() || now,
          searchTerm || undefined,
          paymentMethod,
          paymentStatus
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((response) => {
          this.orders = response.content;
          this.totalOrders = response.totalElements || 0;
        });
    }
  }

  getOrderItems(
    order: OrderDTO,
    type: 'RESOURCE' | 'FEATURE' | 'PREPAIDTICKET'
  ) {
    let result: any[] = [];
    if (order.orderItems?.length) {
      order.orderItems?.forEach((item) => {
        if (item.orderItemType === type) {
          result.push(item);
        }
      });
    }

    return result;
  }

  formatDate(date: Date) {
    return this.dateService.formatDate(date);
  }

  paginator(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.getOrders();
  }

  search(term?: string) {
    this.getOrders(undefined);
  }

  sortChange(e: Sort) {
    if (e.active === 'createdDate')
      if (e.direction === 'asc') this.getOrders('asc');
      else {
        this.getOrders('desc');
      }
  }
}
