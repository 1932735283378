import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { PriceListDTO } from 'parking-sdk';
import { Observable } from 'rxjs';
import { GetPricelists } from 'src/app/core/states/pricelist/pricelist.actions';
import { PricelistState } from 'src/app/core/states/pricelist/pricelist.state';

@Component({
  selector: 'app-copy-pricelist-modal',
  templateUrl: './copy-pricelist-modal.component.html',
  styleUrls: ['./copy-pricelist-modal.component.scss'],
})
export class CopyPricelistModalComponent implements OnInit {
  currentPriceLists$: Observable<PriceListDTO[]>;
  copyPricelistForm?: FormGroup;

  constructor(
    readonly store: Store,
    public dialogRef: MatDialogRef<CopyPricelistModalComponent>,
    readonly formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentPriceLists$ = this.store.select(
      PricelistState.currentPriceLists
    );
  }

  ngOnInit() {
    /* Get pricelists */
    this.store.dispatch(new GetPricelists());

    /* Create form */
    this.copyPricelistForm = this.formBuilder.group({
      name: new FormControl<string | undefined>(undefined),
      selectedPricelist: new FormControl<PriceListDTO | undefined>(
        undefined,
        Validators.required
      ),
    });
  }

  onCopyClick() {
    /* Set default value if no name entered */
    if (!this.copyPricelistForm?.controls['name'].value) {
      this.copyPricelistForm?.controls['name'].setValue(
        `Kopia av ${this.copyPricelistForm.controls['selectedPricelist'].value.name}`
      );
    }

    if (this.copyPricelistForm?.valid)
      this.dialogRef.close({
        ...this.copyPricelistForm.controls['selectedPricelist'].value,
        name: this.copyPricelistForm.controls['name'].value,
      });
  }

  onCancelClick() {
    this.dialogRef.close();
  }
}
