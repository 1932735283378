import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import {
  DefaultService,
  OrderDTO,
  OrderItemDTO,
} from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import { DateService } from 'src/app/core/services/date.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
})
export class ReceiptComponent implements OnInit, OnDestroy {
  @Input() order?: OrderDTO = {};

  vat: number = 0;
  paymentMethods = '';
  receiptNumber = '';
  cashRegister = '';
  transactionDate?: Date;
  pickupInfo?: string[] = [];
  loading = true;

  onDestroy$ = new Subject<void>();

  public ItemType = OrderItemDTO.OrderItemTypeEnum;

  constructor(
    readonly defaultService: DefaultService,
    readonly dateService: DateService
  ) {}

  ngOnInit(): void {
    this.defaultService.getSetting('RECEIPT_FOOTER').pipe(takeUntil(this.onDestroy$)).subscribe((res) => {
      this.pickupInfo = res.value!.split(/<br>/g);
      this.loading = false;
    });

    this.order?.orderItems?.forEach((item) => {
      this.vat += item.vat! * item.amount!;
    });

    if (this.order && this.order.payments && this.order.payments.length === 0) {
      this.transactionDate = this.order.createdDate;
    }

    this.updatePaymentInfo();
  }

  updatePaymentInfo() {
    this.order?.payments?.forEach((payment) => {
      // Get latest transaction date
      if (
        payment.transactionType !== 'REFUND' &&
        (dayjs(this.transactionDate).isBefore(payment.transactionDate) ||
          !this.transactionDate)
      ) {
        this.transactionDate = payment.transactionDate!;
        this.receiptNumber = payment.receiptNumber!;
        this.cashRegister = payment.registerId!;
      }
    });
  }

  formatDate(date?: Date): string | undefined {
    return date && this.dateService.formatDate(date);
  }
  formatTime(date?: Date): string | undefined {
    return date && this.dateService.formatTime(date);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
