import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { BookingDTO, FlightBookingDTO, HourBookingDTO, PartnerDTO } from 'parking-sdk';
import { Subject, interval, switchMap, takeUntil } from 'rxjs';
import { PrintPickupListComponent } from 'src/app/components/print-pickup-list/print-pickup-list.component';
import { DateService } from 'src/app/core/services/date.service';
import {
  GetCurrentBookings,
  GetUpcomingTimeRange,
} from 'src/app/core/states/bookings/booking.actions';
import { BookingsState } from 'src/app/core/states/bookings/bookings.state';
import { UsersState } from 'src/app/core/states/users/users.state';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  currentTopic: 'arrival' | 'departure' = 'arrival';
  groupedArrivalBookings: FlightBookingDTO[] = [];
  groupedDepartureBookings: HourBookingDTO[] = [];
  timeRange?: { fromHour: Date; toHour: Date };
  dispatchBookingsErrorCode: number | undefined = undefined;
  partner?: PartnerDTO
  readonly shortPollTimerMs: number = 1000 * 60 * 5; // 5 minutes
  readonly onDestroy$ = new Subject<void>();

  constructor(
    readonly dateService: DateService,
    readonly store: Store,
    readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.store.select(UsersState.loggedInUser).subscribe((user) => {
      this.partner = user?.roles?.find(
        (role) => (role.name === 'ROLE_PARTNER')
      )?.partner;

      if (!this.partner && user?.id) {
        this.store.dispatch(GetUpcomingTimeRange);
        /* Listen to the dispatch of GetBookings and if there is an error,
       store the error status code in a variable that is uesd in the template 
       to toggle an error message, otherwise reset error code  */
        this.store
          .dispatch(GetCurrentBookings)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe({
            next: () => (this.dispatchBookingsErrorCode = undefined),
            error: (error) => {
              console.error(error);
              this.dispatchBookingsErrorCode = error.status;
            },
            complete: () => (this.dispatchBookingsErrorCode = undefined),
          });

        // retrieve current bookings from state
        this.store
          .select(BookingsState.currentArrivalBookings)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((v) => {
            this.groupedArrivalBookings = v;
          });
        this.store
          .select(BookingsState.currentDepartureBookings)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((v) => {
            this.groupedDepartureBookings = v;
          });

        // retrieve current time range from state
        this.store
          .select(BookingsState.upcomingTimeRange)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((v) => {
            this.timeRange = v;
          });

        /* Start an interval to dispatch GetBooknigs */
        interval(this.shortPollTimerMs)
          .pipe(
            takeUntil(this.onDestroy$),
            switchMap(() => {
              this.store.dispatch(GetUpcomingTimeRange);
              return this.store.dispatch(GetCurrentBookings);
            })
          )
          .subscribe({
            next: () => (this.dispatchBookingsErrorCode = undefined),
            error: (error) => {
              console.error(error);
              this.dispatchBookingsErrorCode = error.status;
            },
            complete: () => (this.dispatchBookingsErrorCode = undefined),
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  changeTopic(value: 'arrival' | 'departure') {
    this.currentTopic = value;
  }

  formatDate(date: string | Date | undefined): string {
    return date ? this.dateService.formatDate(date) : '';
  }

  formatTime(date: string | Date | undefined): string {
    return date ? this.dateService.formatTime(date) : '';
  }

  getTotalPassengers(bookings: BookingDTO[]): number {
    return bookings.reduce(
      (acc: number, booking: BookingDTO) => (booking.qtyPersons || 0) + acc,
      0
    );
  }

  openPrintDialog() {
    const dialogRef = this.dialog.open(PrintPickupListComponent, {
      data: { currentTopic: this.currentTopic },
    });
  }
}
