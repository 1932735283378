import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { environment } from 'src/environments/environment';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(private http: HttpClient) {}

  httpOptions: any = {
    headers: new HttpHeaders({
      'Content-type': 'text/plain',
      Accept: 'text/csv',
    }),
    responseType: 'text',
  };

  public getReportBookings(fromDate: Date, toDate: Date) {
    return this.http
      .get<string>(
        `${
          environment.backendServerUrl
        }/admin/reports/bookings?fromDate=${this.formatDate(
          fromDate
        )}&toDate=${this.formatDate(toDate)}`,
        this.httpOptions
      )
      .subscribe((res) => {
        this.downloadFile('reportBookings.csv', res);
      });
  }

  public getReportAdvancePayments() {
    return this.http
      .get<string>(
        `${environment.backendServerUrl}/admin/reports/economy/advancepayments`,
        this.httpOptions
      )
      .subscribe((res) => {
        this.downloadFile('reportAdvancePayments.csv', res);
      });
  }

  public getReportAdvancePaymentDetails() {
    return this.http
      .get<string>(
        `${environment.backendServerUrl}/admin/reports/economy/advancepayments/detail`,
        this.httpOptions
      )
      .subscribe((res) => {
        this.downloadFile('reportAdvancePaymentDetails.csv', res);
      });
  }

  public getReportArticles(fromDate: Date, toDate: Date) {
    return this.http
      .get<string>(
        `${
          environment.backendServerUrl
        }/admin/reports/economy/articles?fromDate=${this.formatDate(
          fromDate
        )}&toDate=${this.formatDate(toDate)}`,
        this.httpOptions
      )
      .subscribe((res) => {
        this.downloadFile('reportArticles.csv', res);
      });
  }

  public getReportPaymentMethods(fromDate: Date, toDate: Date) {
    return this.http
      .get<string>(
        `${
          environment.backendServerUrl
        }/admin/reports/economy/paymentmethods?fromDate=${this.formatDate(
          fromDate
        )}&toDate=${this.formatDate(toDate)}`,
        this.httpOptions
      )
      .subscribe((res) => {
        this.downloadFile('reportPaymentMethods.csv', res);
      });
  }

  public getReportPartnerBookings(fromDate: Date, toDate: Date, id: number) {
    return this.http
      .get<string>(
        `${
          environment.backendServerUrl
        }/admin/reports/partner/bookings/${id}?fromDate=${this.formatDate(
          fromDate
        )}&toDate=${this.formatDate(toDate)}`,
        this.httpOptions
      )
      .subscribe((res) => {
        this.downloadFile('reportPartnerBookings.csv', res);
      });
  }

  /* Combined report of articles and payment methods */
  public getPaymentReport(fromDate: Date, toDate: Date) {
    return this.http
      .get<string>(
        `${
          environment.backendServerUrl
        }/admin/reports/economy/payments?fromDate=${this.formatDate(
          fromDate
        )}&toDate=${this.formatDate(toDate)}`,
        this.httpOptions
      )
      .subscribe((res) => {
        this.downloadFile('reportPayments.csv', res);
      });
  }

  private formatDate(date: Date): string {
    const d = dayjs(date);
    return d.utc().format('YYYY-MM-DDTHH:mm:ss.000');
  }

  private downloadFile(filename: string, fileContent: any) {
    // Check if fileContent is an array and join it with newline characters
    const content = Array.isArray(fileContent)
      ? fileContent.join('\n')
      : fileContent;

    // Use encodeURIComponent to handle special characters
    const uriContent =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(content);

    const a = document.createElement('a');
    a.href = uriContent;
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
