import { Component, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import dayjs from 'dayjs';
import { DefaultService, PaymentReportDTO } from 'parking-sdk';
import { BarChartDataset } from 'rely-statistics-sdk';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-payment-report',
  templateUrl: './payment-report.component.html',
  styleUrls: ['./payment-report.component.scss'],
})
export class PaymentReportComponent implements OnDestroy {
  from = new FormControl<Date | undefined>(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7,
      0,
      0,
      1
    )
  );
  to = new FormControl<Date | undefined>(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      23,
      59,
      59
    )
  );
  errorMessage = false;
  paymentReport: PaymentReportDTO[] = [];
  chartDataSet: BarChartDataset[] = [];
  chartTitles: string[] = [];
  chartColors: string[] = ['#b1ceab', '#f6a200'];

  loadingData = false;
  setEmpty = true;

  readonly onDestroy$ = new Subject<void>();

  constructor(readonly defaultService: DefaultService) {
    this.getPaymentReport();
  }

  ngOnInit() {
    this.from.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      if (this.to.value) {
        this.to.setValue(undefined, {
          emitEvent: false,
        });
      }
      this.from.value && this.to?.value && this.getPaymentReport();
    });

    this.to?.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        () => this.from.value && this.to?.value && this.getPaymentReport()
      );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getPaymentReport() {
    if (this.to.value && this.from.value) {
      this.loadingData = true;
      this.setEmpty = true;
      this.chartDataSet = [];
      this.chartTitles = [];
      this.paymentReport = [];

      this.defaultService
        .getPaymentReport(
          dayjs(this.from.value).second(0).millisecond(0).toDate(),
          dayjs(this.to.value).second(0).millisecond(0).add(1, 'day').toDate()
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: (reports) => {
            this.paymentReport = [...reports];
            let payments: number[] = [],
              refunds: number[] = [];

            reports.forEach((report) => {
              if (report.payments! > 0 || report.refunds! > 0) {
                this.setEmpty = false;
              }
              payments.push(report.payments!);
              refunds.push(report.refunds!);
              this.chartTitles.push(report.label!);
            });

            this.chartDataSet.push({
              data: payments,
              type: 'bar',
              name: 'Betalningar',
            });
            this.chartDataSet.push({
              data: refunds,
              type: 'bar',
              name: 'Återbetalningar',
            });
            this.loadingData = false;
            this.errorMessage = false;
          },
          error: (error) => {
            console.error('Error fetching payment report: ', error);
            this.loadingData = false;
            this.errorMessage = true;
          },
        });
    }
  }

  getTotalByType(type: 'payments' | 'refunds'): number {
    return (
      this.paymentReport
        ?.map((t) => t[type])
        .reduce((acc, value) => (acc || 0) + (value || 0), 0) || 0
    );
  }
}
