import { Directive } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Directive({
  selector: '[appTabSelect]'
})
export class TabSelectDirective {
  constructor(readonly auto: MatAutocomplete) {}
  ngAfterViewInit() {
    this.auto._keyManager.onKeydown = (event: KeyboardEvent) => {
      switch (event.code) {
        case 'Tab':
          if (!event.shiftKey && this.auto.isOpen) {
            const option = this.auto.options.find(x => x.active);
            if (option) {
              option.select();
              return;
            }
          }
          this.auto._keyManager.tabOut.next();
          break;
        case 'ArrowDown':
          this.auto._keyManager.setNextItemActive();
          break;

        case 'ArrowUp':
          this.auto._keyManager.setPreviousItemActive();
          break;
      }
    };
  }
}
