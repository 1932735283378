import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import {
  DefaultService,
  OrderDTO,
  PaymentDTO,
  StatusValue,
  TerminalDTO,
} from 'parking-sdk';
import { Subject, takeUntil } from 'rxjs';
import {
  CancelCheckout,
  CheckoutOrderPax,
  GetOrder,
  Refund,
  ResetOrderTransState,
  SaveTerminal,
} from 'src/app/core/states/orders/orders.action';
import { OrdersState } from 'src/app/core/states/orders/orders.state';
@Component({
  selector: 'app-pax-payment',
  templateUrl: './pax-payment.component.html',
  styleUrls: ['./pax-payment.component.scss'],
})
export class PaxPaymentComponent implements OnInit, OnDestroy {
  paymentMethod: 'card' | 'bank' = 'card';
  refund = false;
  loading = false;

  availTerminals: TerminalDTO[] = [];
  terminalsFetched: boolean = false;

  transactionStarted: boolean = false;
  showChooseTerminal: boolean = false;
  transactionFinished: boolean = false;
  printOffered: boolean = false;
  selectedTerminal?: TerminalDTO = undefined;
  paymentResponse?: StatusValue[] = [];

  refundAmt?: number;
  refundControl = new FormControl<number | undefined>(this.data.maxRefund, [
    Validators.max(this.data.maxRefund),
    Validators.min(0),
  ]);
  commentControl = new FormControl(undefined, Validators.required);
  paymentControl = new FormControl<number | undefined>(
    this.data.order.amountToPay,
    [
      Validators.max(this.data.order.amountToPay!),
      Validators.min(0),
      Validators.required,
    ]
  );

  form = new FormControl<TerminalDTO | undefined>(undefined);

  readonly onDestroy$ = new Subject<void>();

  constructor(
    readonly store: Store,
    public dialogRef: MatDialogRef<PaxPaymentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      order: OrderDTO;
      refund: PaymentDTO;
      maxRefund: number;
      orderItemId: number;
    },
    readonly defaultService: DefaultService
  ) {
    dialogRef.disableClose = true;
    this.refund = !!data.refund;
  }

  ngOnInit(): void {
    this.defaultService
      .paxFindTerminalsAdmin()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((terminals) => {
        this.availTerminals = terminals;
        this.store
          .select(OrdersState.terminal)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((terminal) => {
            if (terminal) {
              this.form.setValue(terminal);
              this.selectedTerminal = terminal;
              this.terminalsFetched = true;
            }
          });
      });
  }

  /* TODO: better type */
  finalStatus(e: any) {
    if (e.cancelled) {
      this.transactionStarted = false;
      this.paymentResponse = [];
      this.dialogRef.close({ cancelled: true });
    } else if (e.approved) {
      this.transactionFinished = true;
      this.paymentResponse = [];
    } else if (e.ended) {
      this.transactionStarted = false;
      this.paymentResponse = [];
    } else if (e.noConnection) {
      this.transactionStarted = false;
      this.paymentResponse = [];
      this.store.dispatch(new ResetOrderTransState());
      this.dialogRef.close({ unavailable: true });
    }
  }

  changeRefundMethod(value: 'card' | 'bank') {
    this.paymentMethod = value;
  }

  chooseTerminal() {
    this.showChooseTerminal = true;
  }

  bankRefund() {
    let payment: PaymentDTO = {
      paymentMethod: { paymentMethodId: 'BANK' },
      transactionType: 'REFUND',
      amount: this.refundControl.value!,
      comment: this.commentControl.value!,
    };

    this.store
      .dispatch(
        new Refund(
          this.data.order.orderId!,
          this.data.orderItemId,
          this.data.refund.paymentId!,
          payment
        )
      )
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: () => (this.transactionFinished = true),
        error: (error) =>
          console.error(
            'Something went wrong with bank refund. Error: ',
            error
          ),
      });
  }

  startPayment(terminal: TerminalDTO) {
    this.transactionStarted = true;
    this.showChooseTerminal = false;
    this.loading = true;
    this.store.dispatch(new SaveTerminal(terminal));

    // Payment
    if (!this.data.refund) {
      // Create PaymentDTO
      let payment: PaymentDTO = {
        paymentMethod: { paymentMethodId: 'PAX' },
        transactionType: 'PAYMENT',
        terminalId: terminal.terminalId,
        amount: this.paymentControl.value!,
      };

      this.store
        .dispatch(new CheckoutOrderPax(this.data.order.orderId!, payment))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: () => {
            this.loading = false;
          },
          error: (error) => {
            console.error('Error when initiating card payment: ', error);
            this.loading = false;
          },
        });
    } else {
      // Refund
      let payment: PaymentDTO = {
        paymentMethod: this.data.refund.paymentMethod,
        transactionType: 'REFUND',
        amount: this.refundControl.value!,
        terminalId: terminal.terminalId,
        comment: this.commentControl.value!,
      };

      this.store
        .dispatch(
          new Refund(
            this.data.order.orderId!,
            this.data.orderItemId,
            this.data.refund.paymentId!,
            payment
          )
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          this.loading = false;
          this.data.order.orderId &&
            this.store.dispatch(new GetOrder(this.data.order.orderId));
        });
    }
  }

  receiptPrint() {
    this.printOffered = true;
    this.dialogRef.close({
      accepted: true,
      success: true,
      receipt: true,
      paidAmt: this.paymentControl.value!,
    });
  }

  skipPrint() {
    this.printOffered = true;
    this.dialogRef.close({
      accepted: true,
      success: true,
      paidAmt: this.paymentControl.value!,
    });
  }

  cancelPayment(terminalId: number) {
    this.store
      .dispatch(new CancelCheckout(terminalId))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.closeDialog());
  }

  closeDialog() {
    this.dialogRef.close({ cancelled: true });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
