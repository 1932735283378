import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import * as Actions from './reports.actions';
import { DefaultService, ReportTravelerDTO } from "parking-sdk";
import { tap } from "rxjs";


export interface ReportsStateModel {
  reports: ReportTravelerDTO[];
}

@State<ReportsStateModel>({
  name: 'reports',
  defaults: {
    reports: []
  }
})
@Injectable()
export class ReportsState {
  constructor(readonly defaultService: DefaultService) {}

  @Selector()
  static currentReports(state: ReportsStateModel) {
    return state.reports;
  }

  @Action(Actions.GetReport)
  getReport(context: StateContext<ReportsStateModel>, action: Actions.GetReport) {
    const {fromDate, toDate} = action;
    return this.defaultService.getReportTravelers(fromDate, toDate).pipe(
      tap((report) => {
        context.setState({reports: report});
      })
    );
  }
}