import { Component, OnInit } from '@angular/core';
import { RoleDTO, UserDTO } from 'parking-sdk';
import { Subject, map, takeUntil } from 'rxjs';
import { EditUserComponent } from 'src/app/components/edit-user/edit-user.component';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { UsersState } from 'src/app/core/states/users/users.state';
import { AddUser, GetUsers } from 'src/app/core/states/users/user.actions';
import { ErrorDialogComponent } from 'src/app/components/error-dialog/error-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-administration',
  templateUrl: './user-administration.component.html',
  styleUrls: ['./user-administration.component.scss'],
})
export class UserAdministrationComponent implements OnInit {
  users?: UserDTO[];

  readonly onDestroy$ = new Subject<void>();

  constructor(readonly store: Store, readonly dialog: MatDialog, readonly snackBar: MatSnackBar) {
    this.store
      .select(UsersState.currentUsers)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((v) => {
        this.users = v;
      });
  }

  ngOnInit() {
    this.store.dispatch(GetUsers);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
      .trim()
      .toLowerCase();
    if (filterValue === '') {
      this.store
        .select(UsersState.currentUsers)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((v) => (this.users = v));
    } else {
      this.store
        .select(UsersState.currentUsers)
        .pipe(
          map((users) =>
            users.filter(
              (user) =>
                user.id?.toString().includes(filterValue) ||
                user.username?.toLowerCase().includes(filterValue) ||
                user.roles?.some(
                  (role) =>
                    role.label && role.label.toLowerCase().includes(filterValue)
                )
            )
          )
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((v) => (this.users = v));
    }
  }

  openEditDialog(user?: UserDTO): void {
    const context = user
      ? { width: '600px', data: { user } }
      : { width: '600px', data: undefined };

    const dialogRef = this.dialog.open(EditUserComponent, context);

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        if (result) {
          // Use the values from the dialog result to create a new user
          const newUser: UserDTO = {
            username: result.username,
            roles: result.roles as RoleDTO[],
            newPassword: result.newPassword,
          };

          this.store
            .dispatch(new AddUser(newUser))
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
              next: () => {
                this.snackBar.open('Ny användare skapad', 'OK', {
                  duration: 3000,
                });
              },
              error: (error) => {
                console.error('Error creating user: ', error);
                this.dialog.open(ErrorDialogComponent, {
                  data: {
                    title: 'Det gick inte att skapa användaren',
                    message: ['Var god prova igen om en liten stund.'],
                  },
                });
              },
            });
        } else {
          this.store.dispatch(GetUsers);
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
